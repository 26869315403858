@use '../core/' as *;

@mixin atlas-data-table() {
  .op-data-table {
    &.mat-mdc-table {
      tbody > tr:last-of-type {
        td.mat-mdc-cell {
          @include border-bottom();
        }
      }

      td.mat-mdc-cell,
      th.mat-mdc-header-cell,
      td.mat-mdc-footer-cell {
        vertical-align: top;

        &.label {
          width: $table-label-width;
          @include op-media-mobile {
            width: $table-label-width-mobile;
          }
        }

        &.operator {
          text-align: center;
          width: $table-operator-width;
        }
      }

      td.mat-mdc-footer-cell {
        font-weight: bold;
        border-bottom: none;
      }
    }
  }
}
