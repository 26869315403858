@use '../core/' as *;

@mixin atlas-responsive() {
  .op-hide-mobile {
    @include op-media-mobile {
      display: none !important;
    }
  }

  .op-hide-desktop {
    @include op-media-desktop {
      display: none !important;
    }
  }
}
