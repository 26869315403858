@use '../../core' as *;

op-card .op-form-skinny {
  width: 50%;
}

@media (max-width: 1024px) {
  op-card .op-form-skinny {
    width: 100%;
  }
}

.op-form-full-page {
  position: relative;

  // save room for the footer
  margin-bottom: $op-form-footer-height + spacing(5);
}

.op-form-row {
  @include flex-horizontal($align: top);

  > * {
    flex: 1;
  }
}

.op-form-nested {
  width: 100%;

  > *:first {
    @include margin-bottom(2);
  }
}
