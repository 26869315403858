@use '../core/' as *;
@use 'overlay' as *;

@mixin atlas-tooltip() {
  .cdk-overlay-container .mdc-tooltip {
    @include margin-vertical(1);

    &__surface {
      @include op-overlay-dark();
    }
  }
}
