@use '../core/' as *;

@mixin select-cells-and-headers() {
  .mdc-data-table {
    &__cell,
    &__header-cell {
      @content;
    }
  }
}

@mixin atlas-table() {
  .mat-mdc-table {
    width: 100%;

    .mdc-data-table {
      &__row,
      &__header-row {
        height: auto;
      }
    }

    &.op-table-sm {
      @include select-cells-and-headers {
        @include text-body-small($color: color(text-primary));
      }
    }

    &:not(.op-pad-ends) {
      @include select-cells-and-headers {
        &:first-of-type {
          @include padding-left(0);
        }

        &:last-of-type {
          @include padding-right(0);
        }
      }
    }

    @include select-cells-and-headers {
      @include border-bottom($color: color(structure-light));
      @include padding(2);
    }

    tfoot tr:first-of-type td {
      @include border-top($color: color(structure));
    }

    .op-data-table-sub-text {
      @include text-body-small($color: color(text-secondary));
    }
  }
}
