@use '../../core' as *;

@mixin atlas-form-field-subscript() {
  .mat-mdc-form-field-hint {
    @include text-body-small($color: color(text-secondary));
  }

  .mat-mdc-form-field-error-wrapper,
  .mat-mdc-form-field-hint-wrapper {
    @include padding(0);
  }
}
