@use '../core' as *;

@mixin atlas-flex() {
  .op-flex-vertical {
    @include flex-vertical;
  }

  .op-flex-horizontal {
    @include flex-horizontal;
  }

  .op-flex-centered {
    @include flex-centered;
  }

  @each $align in (stretch, left, center, right) {
    .op-flex-vertical-#{$align} {
      @include flex-vertical($align: $align);
    }
  }
  @each $align in (stretch, top, center, bottom, baseline) {
    .op-flex-horizontal-#{$align} {
      @include flex-horizontal($align: $align);
    }
  }

  @each $gap in (1, 2, 3, 5, 6) {
    .op-flex-vertical-#{$gap} {
      @include flex-vertical($gap: $gap);
    }

    @each $align in (stretch, left, center, right) {
      .op-flex-vertical-#{$gap}-#{$align} {
        @include flex-vertical($gap: $gap, $align: $align);
      }
    }

    .op-flex-horizontal-#{$gap} {
      @include flex-horizontal($gap: $gap);
    }

    @each $align in (stretch, top, center, bottom, baseline) {
      .op-flex-horizontal-#{$gap}-#{$align} {
        @include flex-horizontal($gap: $gap, $align: $align);
      }
    }
  }
}
