@use '../../core' as *;

@mixin atlas-checkbox() {
  .mat-mdc-checkbox {
    display: block;

    @include margin-bottom(3);

    label {
      margin-bottom: 0;
    }

    --checkbox-color: #{color(structure-dark)};
    &.mat-mdc-checkbox-checked {
      --checkbox-color: #{color(interaction)};
    }

    .mdc-checkbox {
      @include padding(0); // comes with a big padding

      .mdc-checkbox__background {
        // this was shifted to compensate for the wide circle outline
        top: 0;
        left: 0;
      }

      .mdc-checkbox__ripple {
        display: none;
      }
    }

    // set color
    --checkbox-color: #{color(structure-dark)};
    &.mat-mdc-checkbox-checked {
      --checkbox-color: #{color(interaction)};
    }

    .mdc-checkbox__background {
      /**
        This is important because the material target for the checkbox border
        is ABSURDLY specific. It looks at the actual native html control nearby
        to get whether the checkbox is actually checked. Not sure why, but they have
        a parent class with the checked status already.
      */
      border-color: var(--checkbox-color) !important;
    }
  }
}
