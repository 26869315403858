@use 'sass:map';
@use 'responsive' as *;

$base-font-family: 'Inter';
$base-font-size: 16px !default;

$font-weights: (
  'normal': 400,
  'bold': 600,
);

@function font-weight($weight: 'regular') {
  $result: map.get($font-weights, $weight);
  @if $result != null {
    @return $result;
  }
  @error 'The weight \'#{$weight}\' is not a supported design system font-weight';
}

$body-font-size: 14px;
$body-line-height: 20px;

$extra-small-font-size: 10px;
$small-font-size: 12px;
$small-line-height: 18px;

@mixin text(
  $font-size: false,
  $font-weight: false,
  $line-height: false,
  $color: false
) {
  font-family: $base-font-family;
  letter-spacing: normal;

  @if $font-size {
    font-size: $font-size;
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $line-height {
    line-height: $line-height;
  }
  @if $color {
    color: $color;
  }
}

@mixin text-page-title {
  @include op-media-desktop {
    @include text($font-size: 28px, $font-weight: 700, $line-height: 34px);
  }
  @include op-media-mobile {
    @include text($font-size: 24px, $font-weight: 700, $line-height: 32px);
  }
  @content;
}

@mixin text-subheader {
  @include text($font-size: 22px, $font-weight: 500, $line-height: 27px);
  @content;
}

@mixin text-card-header {
  @include text($font-size: 18px, $font-weight: 500, $line-height: 22px);
  @content;
}

@mixin text-field-group-header {
  @include text(
    $font-size: 10px,
    $font-weight: font-weight('bold'),
    $line-height: 14px
  );
  letter-spacing: 1px;
  text-transform: uppercase;
  @content;
}

@mixin text-field-label {
  @include text(
    $font-size: 10px,
    $font-weight: font-weight('normal'),
    $line-height: 12px
  );
  @content;
}

@mixin text-body($bold: false, $color: false) {
  $font-weight: if($bold, font-weight('bold'), font-weight('normal'));
  @include text(
    $font-size: $body-font-size,
    $font-weight: $font-weight,
    $line-height: $body-line-height,
    $color: $color
  );
  @content;
}

@mixin text-body-secondary() {
  @include text-body($color: color(text-secondary));
  @content;
}

@mixin text-body-bold($bold: false, $color: false) {
  @include text-body($bold: true, $color: $color);
  @content;
}

@mixin text-body-small($bold: false, $color: false) {
  $font-weight: if($bold, font-weight('bold'), font-weight('normal'));
  @include text(
    $font-size: $small-font-size,
    $font-weight: $font-weight,
    $line-height: $small-line-height,
    $color: $color
  );
  @content;
}

@mixin text-body-small-bold($bold: false, $color: false) {
  @include text-body-small($bold: true, $color: $color);
  @content;
}

@mixin text-body-extra-small($bold: false, $color: false) {
  $font-weight: if($bold, font-weight('bold'), font-weight('normal'));
  @include text(
    $font-size: $extra-small-font-size,
    $font-weight: $font-weight,
    $line-height: $small-line-height,
    $color: $color
  );
  @content;
}

@mixin text-body-extra-small-bold($bold: false, $color: false) {
  @include text-body-small($bold: true, $color: $color);
  @content;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} ;
