$grid-cols: 12;
$grid-col-gap: 30px;
$grid-row-gap: 24px;

@mixin op-col($width: $grid-cols) {
  grid-column: auto / span $width;
}

@mixin op-offset($width: $grid-cols) {
  grid-column-start: $width;
}

@mixin grid-eq-cols($columns) {
  grid-template-columns: repeat($columns, 1fr);
}

@mixin grid($columns: $grid-cols) {
  display: grid;
  column-gap: $grid-col-gap;
  row-gap: $grid-row-gap;
  @include grid-eq-cols($columns);
}

@mixin atlas-grid() {
  @layer op-grid-base {
    .op-grid {
      @include grid();
    }

    @for $i from 2 through 12 {
      .op-grid-#{$i} {
        @include grid($columns: $i);
      }
    }

    @for $i from 1 through $grid-cols {
      .op-col-#{$i} {
        @include op-col($i);
      }
    }

    @for $i from 1 through $grid-cols {
      .op-offset-#{$i} {
        @include op-offset($i + 1);
      }
    }
  }

  @layer op-grid-size-overrides {
    @for $i from 1 through $grid-cols {
      .op-col-md-#{$i} {
        @media (max-width: 767px) {
          @include op-col($i);
        }
      }
    }
  }
}
