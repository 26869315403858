@use '@angular/material' as mat;
@use '../core' as *;

@mixin atlas-menus() {
  .mat-mdc-menu-panel {
    @include radius;
    @include border;
    @include mat.elevation(1);

    min-height: 0;
  }

  .mat-mdc-menu-content:not(:empty) {
    @include padding-vertical(0);
  }

  a,
  button {
    &.mat-mdc-menu-item {
      height: auto;
      min-height: auto;

      @include padding-horizontal(4);
      @include padding-vertical(2);

      background-color: color('white');

      @include text-body;
      text-align: left;

      .mat-icon-no-color {
        color: color(text-primary);
      }

      &:focus {
        outline: none;
      }

      &:hover:disabled {
        background-color: none;
      }

      &:hover:not(:disabled) {
        background-color: color(interaction-lightest);

        text-decoration: none;
      }

      &:disabled {
        opacity: 1;
      }

      .mat-icon {
        width: auto;

        @include margin-right(0);
      }
    }
  }
}
