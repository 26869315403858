@use 'sass:list';
@use 'typography' as *;

$spacing: 0 4px 8px 12px 16px 24px 32px 40px 48px 64px 80px 96px 112px 160px;

@function spacing($i) {
  @if unit($i) != '' {
    @return $i;
  }

  $index: abs($i);
  $length: length($spacing);

  @if $index < 0 and $index > $length {
    @error "'#{$i}' is not between #{-1 * $length + 1} and #{$length - 1}, please check your code.";
  }

  $spacing: list.nth($spacing, $index + 1);
  @if $spacing != null {
    @if $i < 0 {
      @return -$spacing;
    }
    @return $spacing;
  }
}

$edge-padding: spacing(4);

@mixin padding($i) {
  padding: spacing($i);
}

@mixin padding-top($i) {
  padding-top: spacing($i);
}

@mixin padding-bottom($i) {
  padding-bottom: spacing($i);
}

@mixin padding-left($i) {
  padding-left: spacing($i);
}

@mixin padding-right($i) {
  padding-right: spacing($i);
}

@mixin padding-horizontal($i) {
  @include padding-left($i);
  @include padding-right($i);
}

@mixin padding-vertical($i) {
  @include padding-top($i);
  @include padding-bottom($i);
}

@mixin margin($i) {
  margin: spacing($i);
}

@mixin margin-top($i) {
  margin-top: spacing($i);
}

@mixin margin-bottom($i) {
  margin-bottom: spacing($i);
}

@mixin margin-left($i) {
  margin-left: spacing($i);
}

@mixin margin-right($i) {
  margin-right: spacing($i);
}

@mixin margin-horizontal($i) {
  @include margin-left($i);
  @include margin-right($i);
}

@mixin margin-vertical($i) {
  @include margin-top($i);
  @include margin-bottom($i);
}

@mixin gap($i) {
  gap: spacing($i);
}
