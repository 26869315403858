@use 'shared' as *;

@mixin atlas-layers() {
  @layer op-grid-base, op-grid-size-overrides;

  /**
   * Sets the CSS layer ordering. Setting this here allows us to define/redefine layers later, without having to worry
   * about layer precedence since that is established by this.
   */
}
