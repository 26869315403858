@use 'sass:map';
@use 'spacing' as *;

$horizontal-alignment-map: (
  left: flex-start,
  right: flex-end,
);

$horizontal-justify-map: (
  left: flex-start,
  right: flex-end,
);

$vertical-alignment-map: (
  top: flex-start,
  bottom: flex-end,
);

$vertical-justify-map: (
  top: flex-start,
  bottom: flex-end,
);

@function flex-translate($map, $name) {
  $result: map.get($map, $name);
  @if $result != null {
    @return $result;
  }

  @return $name;
}

/**
  Useful for centering something in the middle of a container
*/
@mixin flex-centered() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**
  Useful for adding a margin to a successive list of elements, but ignore the last one.
*/
@mixin flex-vertical($gap: 4, $align: stretch, $justify: top) {
  display: flex;
  flex-direction: column;
  justify-content: flex-translate($vertical-justify-map, $justify);
  align-items: flex-translate($vertical-alignment-map, $align);

  @include gap($gap);
}

/**
  Useful for adding a margin to a successive list of elements, but ignore the last one.
*/
@mixin flex-horizontal($gap: 4, $align: center, $justify: left) {
  display: flex;
  flex-direction: row;
  justify-content: flex-translate($horizontal-justify-map, $justify);
  align-items: flex-translate($horizontal-alignment-map, $align);

  @include gap($gap);
}
