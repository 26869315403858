@use 'radius' as *;
@use 'colors' as *;

@mixin border(
  $position: 'all',
  $width: 1px,
  $style: solid,
  $color: color(structure),
  $radius: none
) {
  @if $position == 'all' {
    border-width: $width;
    border-style: $style;
    border-color: $color;
  } @else {
    border-#{$position}-width: $width;
    border-#{$position}-style: $style;
    border-#{$position}-color: $color;
  }

  @if $radius != none {
    @include radius($radius);
  }

  @content;
}

@mixin border-top(
  $width: 1px,
  $style: solid,
  $color: color(structure),
  $radius: none
) {
  @include border('top', $width, $style, $color, $radius) {
    @content;
  }
}

@mixin border-right(
  $width: 1px,
  $style: solid,
  $color: color(structure),
  $radius: none
) {
  @include border('right', $width, $style, $color, $radius) {
    @content;
  }
}

@mixin border-bottom(
  $width: 1px,
  $style: solid,
  $color: color(structure),
  $radius: none
) {
  @include border('bottom', $width, $style, $color, $radius) {
    @content;
  }
}

@mixin border-left(
  $width: 1px,
  $style: solid,
  $color: color(structure),
  $radius: none
) {
  @include border('left', $width, $style, $color, $radius) {
    @content;
  }
}
