@use '../core' as *;

@mixin atlas-text-helpers() {
  .op-text-bold {
    font-weight: font-weight('bold') !important;
  }

  .op-page-title {
    @include text-page-title;
  }

  .op-subheader {
    @include text-subheader;
  }

  .op-card-title {
    @include text-card-header;
  }

  .op-field-group-header {
    @include text-field-group-header;
  }

  .op-field-label {
    @include text-field-label;
  }

  .op-body {
    @include text-body;
  }

  .op-body-small {
    @include text-body-small;
  }

  .op-body-bold {
    @include text-body-bold;
  }

  .op-body-small-bold {
    @include text-body-small-bold;
  }

  @each $type in primary, secondary, placeholder {
    .op-text-#{$type} {
      color: color(text-#{$type}) !important;
    }
  }

  .op-text-white {
    color: color('white') !important;
  }

  @each $type in $color-types {
    @each $shade in $color-shades {
      $color: color($type);
      @if $shade != main {
        $color: color-no-error('#{$type}-#{$shade}');
      } @else {
        .op-bg-#{$type} {
          background-color: $color !important ;
        }
        .op-text-#{$type} {
          color: $color !important;
        }
      }

      @if $color !=null {
        .op-bg-#{$type}-#{$shade} {
          background-color: $color !important;
        }
        .op-text-#{$type}-#{$shade} {
          color: $color !important;
        }
      }
    }
  }
}
