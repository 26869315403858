@import 'mixins/mixins.scss';
@import 'variables';
@import 'orchard-material-theme';

/**
 * These are here because we needed to use some of the inner
 * bootstrap mixins for our styles. Usually you just include
 * the bootstrap css (not scss) file in your styles and that's it.
 *
 * Because we do this manually our selves, we have to also
 * include the datepicker scss for ngx-bootstrap/datepicker,
 * as the date picker won't look right if we don't have it.
 */
@import 'bootstrap/scss/bootstrap.scss';
@import 'ngx-bootstrap/datepicker/bs-datepicker.scss';

@import 'global';
@import 'typography';
