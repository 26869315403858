@use '../core' as *;

@mixin atlas-dialog() {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      @include radius(xl);
      @include padding(0);

      ng-component,
      > *,
      ng-component > * {
        min-height: inherit;
      }

      .mdc-dialog__content {
        @include padding-vertical(4);
        @include padding-horizontal(5);

        color: color(text-primary);
      }
    }
  }

  .op-modal-backdrop {
    @include op-overlay-backdrop;
  }
}
