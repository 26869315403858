@use '../../core' as *;

@mixin atlas-input() {
  .mat-mdc-form-field {
    .mdc-text-field {
      @include border();
      @include radius(sm);

      @include padding-horizontal(0);

      &:not(.mdc-text-field--disabled) {
        background-color: color('white');
      }

      &.mdc-text-field--focused {
        border-color: color(interaction);
      }

      &.mdc-text-field--invalid {
        border-color: color(alert);
      }

      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 23px;
        line-height: inherit; // material sets this to normal, which cuts off some text

        &.mdc-floating-label--float-above {
          top: 25px;
        }
      }
    }
  }
}
