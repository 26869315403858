$breakpoint-mobile: 768px;
$breakpoint-phone: 425px;

@mixin op-media-phone {
  @media (max-width: $breakpoint-phone) {
    @content;
  }
}

@mixin op-media-mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin op-media-desktop {
  @media (min-width: ($breakpoint-mobile + 1)) {
    @content;
  }
}
