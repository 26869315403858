@use '../../core' as *;

$atlas-radio-radius: 20px;

@mixin atlas-radio() {
  .mat-mdc-radio-group {
    @include flex-vertical(3);
  }

  .mat-mdc-radio-label {
    display: flex;
    align-items: center;
  }

  .mat-mdc-radio-button {
    display: block;

    .mdc-form-field {
      .mdc-radio {
        @include padding(0);

        .mat-radio-ripple {
          display: none; // clears out circle outline on click
        }

        .mdc-radio__background {
          &::before {
            content: none; // clears out circle outline around on hover
          }

          height: $atlas-radio-radius;
          width: $atlas-radio-radius;

          .mdc-radio__outer-circle {
            height: $atlas-radio-radius;
            width: $atlas-radio-radius;
          }

          .mdc-radio__inner-circle {
            height: $atlas-radio-radius;
            width: $atlas-radio-radius;
          }
        }
      }

      label {
        @include margin(0);
      }
    }

    // set radio colors
    --radio-color: #{color(structure-dark)};
    &.mat-mdc-radio-checked {
      --radio-color: #{color(interaction)};
    }

    .mdc-radio__outer-circle {
      /**
        This is important because the material target for the radio border
        is ABSURDLY specific. It looks at the actual native html control nearby
        to get whether the checkbox is actually checked. Not sure why, but they have
        a parent class with the checked status already.
      */
      border-color: var(--radio-color) !important;
    }
  }
}
