@use '../core' as *;

@mixin atlas-icons {
  .mat-icon {
    &.op-icon-sm {
      @include op-icon($op-icon-size-sm);
    }

    &.op-icon-md,
    & {
      @include op-icon($op-icon-size);
    }

    &.op-icon-lg {
      @include op-icon($op-icon-size-lg);
    }

    &.mat-icon-inline {
      /*
        These are repeated here because an ordering issue with form field hints.
        Some times that's included first, some times, mat-icon is. Depending on
        which, the icon will look misaligned. Hopefully we can remove this in the
        future as this issue is fixed.
      */
      height: inherit;
      width: inherit;
      line-height: inherit;

      font-size: calc(1em + $op-icon-upsizing);
      vertical-align: bottom;
    }
  }

  .op-indent-for-icon {
    @include offset-for-icon;
  }
}
