@use '@atlas/design-system' as op;

@include op.use-design-system;

.use-design-system {
  @include op.text-body($color: color(text-primary));

  h1 {
    @include op.text-page-title;
  }

  h2 {
    @include op.text-subheader;
  }

  h3 {
    @include op.text-card-header;
  }

  h4 {
    @include op.margin-bottom(1);
    @include op.text-body-bold;
  }

  section:not(:last-child) {
    @include op.margin-bottom(5);
  }
}

.op-main {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  @include op.flex-vertical(5);

  @include op.padding-top(8);
  @include op.padding-horizontal(4);

  @include op.op-media-mobile {
    @include op.padding-top(4);
  }
}
