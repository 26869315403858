@use '../core/' as *;

@mixin atlas-autocomplete() {
  .mdc-menu-surface.mat-mdc-autocomplete-panel {
    max-height: none;
  }

  .mdc-list-item__primary-text {
    width: 100%;
  }
}
