$font-size-base: .825rem;

$perch-primary: #31324c;

/* Colors */
$primary: #31324c;
$secondary: #8f959c;
$success-color: #28A745;
$warning-color: #CCAB52;
$dark: #31324c;
$birch: #050505;
$light: #F3F3F3;
$birch-light: #f9f8f5;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success-color,
  "warning": $warning-color,
  "dark": $dark
);

$atlas-background: #F5F5F5;

$text-gray: #707070;
$text-gray-darker: #474747;
$text-muted: #a9afb3;

$link-color: #337ab7;

$font-weight-bold: 500;

$border-light: #e0e0e0;

/* Buttons */
$input-btn-padding-y-lg: .6rem;
$input-btn-padding-x-lg: 1.2rem;

/* Navbar */
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1rem;
$navbar-nav-link-padding-x: .76rem;
$navbar-light-color: $link-color;
$navbar-mobile-height: 53px;

/* Tables */
$table-cell-padding: .4rem .5rem;

/* Modals */
$modal-md: 600px;

/* Carousels */
$carousel-control-color: #303030;

/* Badges */
$badge-font-size: 0.825rem;
$badge-font-weight: 400;
$badge-padding-y: .45em;
$badge-padding-x: .8em;
$badge-border-radius: 20px;

/* Forms */
$label-margin-bottom: .25rem;
$input-placeholder-color: #adb1b5;

/* Datepicker Themes */
$theme-list: (
  'perch': $perch-primary
);

/* Toggle */
$custom-control-indicator-checked-bg: $link-color;
