@use '../core' as *;

/**
 * This mixin is intended to be called in the application's root stylesheet, in order to apply all Altas-specific
 * definition list style overrides. Since these target Angular Material styles and components, they need to be
 * global scope, instead of component scoped.
 */
@mixin atlas-definition-list() {
  dl.op-dl {
    @include margin-bottom(0);

    dt {
      @include text-field-group-header {
        color: color(text-secondary);
      }
      @include margin-bottom(1);
      text-transform: uppercase;
    }

    dd {
      @include text-body {
        color: color(text-primary);
      }

      margin-inline-start: 0px;
      @include margin-bottom(4);
    }

    /**
     * If the dl is multiple definitions per row, clear out the margin-bottom on the dd, because
     * the grid already provides a vertical margin between stuff.
     */
    @for $i from 2 through 12 {
      &.op-grid-#{$i} dd {
        @include margin-bottom(0);
      }
    }
  }
}
