@mixin material_icon($icon) {
  content: $icon;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  width:auto;
  height:auto;
  vertical-align:center;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}