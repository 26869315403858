@use '../../core' as *;

@mixin atlas-form-field-affixes() {
  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  &.no-label .mat-mdc-form-field-infix {
    @include padding-vertical(3);
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
    padding-top: 18px;
    @include padding-bottom(2);
    @include padding-horizontal(3);

    width: auto;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: stretch;

    @include padding-horizontal(3);

    background-color: color(structure-light);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    color: color(text-secondary);

    .mat-icon {
      @include op-icon($op-icon-size-lg);
      @include padding(0);
    }
  }

  .mat-mdc-form-field-icon-prefix {
    @include border-right;
  }
  .mat-mdc-form-field-icon-suffix {
    @include border-left;
  }
}
