@use '@angular/material' as mat;
@use '../core/' as *;

@mixin atlas-expansion-panel() {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    // the spacing 1 is for the icon padding
    $label-width: $table-label-width - spacing($table-padding) - spacing(1);

    &:first-of-type,
    &:last-of-type {
      border-radius: 0;
    }

    @include mat.elevation(0);
    @include padding-vertical($table-padding);

    ::ng-deep .mat-content {
      align-items: flex-start;
    }

    .mat-expansion-panel-header-title {
      @include margin-right(0);

      flex-grow: 0;
      flex-basis: $label-width;
      align-items: flex-start;

      @include text-body-small-bold;
    }

    .mat-expansion-panel-body,
    .mat-expansion-panel-header {
      height: auto;

      @include padding-horizontal(0);
      @include padding-right($table-padding);
    }

    .mat-expansion-panel-header-description {
      @include text-body($color: color(text-primary));
    }

    .mat-expansion-indicator::after {
      color: color(text-secondary);
    }

    .mat-expansion-panel-header {
      &.cdk-keyboard-focused:not([aria-disabled='true']),
      &.cdk-program-focused:not([aria-disabled='true']),
      &:not(.mat-expanded),
      &:hover:not([aria-disabled='true']) {
        background: none;
      }

      .mat-panel-description {
        display: block;
      }

      .mat-expansion-indicator {
        align-self: flex-start;
        line-height: 16px;
      }

      &.mat-expanded .mat-expansion-indicator {
        position: relative;
        top: 4px;
      }
    }

    .mat-expansion-panel-content {
      @include text-body-small($color: color(text-primary));

      .mat-expansion-panel-body {
        @include padding-vertical(0);

        .align-with-description {
          margin-left: $label-width;
        }
      }
    }

    @include border-bottom($color: color(structure-lightest));
  }
}
