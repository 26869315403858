@use 'sass:map';

$border-radii: (
  sm: 4px,
  md: 8px,
  lg: 16px,
  xl: 24px,
);

@mixin radius-top-left($size: md) {
  border-top-left-radius: map.get($border-radii, $size);
}
@mixin radius-top-right($size: md) {
  border-top-right-radius: map.get($border-radii, $size);
}
@mixin radius-bottom-left($size: md) {
  border-bottom-left-radius: map.get($border-radii, $size);
}
@mixin radius-bottom-right($size: md) {
  border-bottom-right-radius: map.get($border-radii, $size);
}

@mixin radius-top($size: md) {
  @include radius-top-right($size);
  @include radius-top-left($size);
}

@mixin radius-bottom($size: md) {
  @include radius-bottom-right($size);
  @include radius-bottom-left($size);
}

@mixin radius-left($size: md) {
  @include radius-top-right($size);
  @include radius-bottom-right($size);
}

@mixin radius-right($size: md) {
  @include radius-top-left($size);
  @include radius-bottom-left($size);
}

@mixin radius($size: md) {
  border-radius: map.get($border-radii, $size);
}
