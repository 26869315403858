@use '../../core' as *;

@mixin atlas-form-error() {
  .mat-mdc-form-field-error {
    .mat-icon {
      @include margin-right(1);
    }

    @include text-body-small($color: color(alert));
  }
}
