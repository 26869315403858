@use '../../core' as *;
@use 'subscript' as *;
@use 'affixes' as *;

@mixin atlas-form-field() {
  .mat-mdc-form-field {
    @include margin-bottom(4);
    width: 100%;

    @include atlas-form-field-affixes();
    @include atlas-form-field-subscript();
  }
}
