@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons-outlined.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons-round.woff2') format('woff2');
}

.material-icons.outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons.round {
  font-family: 'Material Icons Round';
}
