@use '@angular/material' as mat;
@use '../../core' as *;

$op-select-option-height: 36px;

@mixin atlas-select() {
  .mat-pseudo-checkbox {
    display: none;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    @include mat.elevation(0);

    @include margin-top(1);

    @include border($color: color(interaction));

    @include padding(0);

    .mat-mdc-optgroup-label,
    .mat-mdc-option {
      height: $op-select-option-height;
      line-height: $op-select-option-height;
    }

    .mat-mdc-option {
      &:not(.mat-option-disabled) {
        &.mat-active,
        &:hover,
        &:focus {
          background: color(interaction-lightest);
        }
      }
    }
  }

  // rotating triangle
  .mat-mdc-select {
    $initial-rot: 0deg;
    $size: 6px;
    $arrow-offset: -4px;

    .mat-mdc-select-arrow-wrapper {
      height: $op-icon-size-lg;
    }

    &[aria-expanded='true'] .mat-mdc-select-arrow {
      transform: rotate($initial-rot + 180deg);
    }

    .mat-mdc-select-arrow {
      transform: rotate($initial-rot);
      transition: transform 0.25s;
    }
  }
}
