@use '@atlas/design-system';

@import 'mixins/mixins.scss';
@import 'variables';

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  font-family: 'Roboto';
  color: #31324c;
  background-color: $atlas-background;
}

@media print {
  body {
    padding-top: 0;
  }
}

h1,
.h1,
h2,
.h2,
h4,
.h4,
h5,
.h5 {
  font-weight: 400;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 48px;
}
h2,
.h2 {
  font-size: 22px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}

/* HELPERS -- BOOTSTRAP 4 */
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.text-sm {
  font-size: $font-size-base * 0.9;
}

.text-lg {
  font-size: $font-size-base * 1.5;
}

.link {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

a:not([href]):not([tabindex]) {
  color: $link-color;
}

/*
 * Global add-ons
 */

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/*
 * Main content
 */

.main .container-fluid,
.main .container {
  max-width: 1320px;
}
.main .container-fluid:not(.property-page-container):not(.footer-container) {
  min-width: 1000px;
}

.main .page-header {
  margin-top: 25px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .main .page-header {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

/*
 * Helper Classes
 */
.field-editable {
  border-bottom: 1px dashed #008cba;
  cursor: pointer;

  &.text-danger {
    border-bottom-color: $danger;
  }
}

.form-errors {
  color: $danger;
  font-size: 0.75rem;
}

.empty:after {
  content: '\A0';
}

hr.dashed {
  border-style: dashed;
}

.hr-dashed {
  width: 100%;
  border-style: dashed;
  border-color: #d5d5d5;
  margin-top: 1rem;
}

.well {
  padding: 1rem;
  background: #fff;
  border: 1px solid $border-light;
  margin-bottom: 22px;
  overflow-y: auto;

  &__divider {
    display: block;
    margin: 0 -1rem;
    background: #d5d5d5;
    height: 2px;
  }

  &.well-v2 {
    background-color: #fff;
    border-radius: 4px;
    padding: 0.75rem;

    box-shadow: 0 0 8px rgba(#ccc, 0.3);
  }
}

.well.p-0 {
  .table tr {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Content module-structural styles */
.content-wrap {
  padding: 15px;
}
.content-wrap-white-bg {
  background: #fff;
}

.well .content-heading {
  border-color: #ddd;
}
.content-heading {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}
.content-heading:after {
  content: '';
  display: table;
  clear: both;
}
.content-heading > h2,
.content-heading > h3,
.content-heading > h4 {
  float: left;
  margin-top: 0;
}

/*
 * Tables
 */
.table {
  font-size: 12px;
  color: #686868;

  > thead > tr {
    > th {
      line-height: 14px;
      color: #9b9b9b;
      font-size: 11px;
      font-weight: 500;
    }

    &:first-child > th {
      border-top: 0;
    }
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        vertical-align: middle;
      }
    }
  }

  &:not(.table-borderless) {
    @extend .border-bottom;
  }

  &.table-responsive {
    overflow: visible;
  }

  &.table-sticky-header {
    > thead > tr > th {
      border: 0;
    }
    > thead > tr.groupHeader > th {
      color: inherit;
      text-align: center;
      padding-top: 10px;

      &.has_content {
        border: 1px solid $border-light;
        border-bottom-width: 0;
      }
    }
    > thead > tr:not(.groupHeader) > th {
      position: sticky;
      top: 67px;
      background: #fff;
      padding-top: 8px;
      padding-bottom: 8px;
      z-index: 1;

      &:after {
        content: '';
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        height: 0;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
    > thead > tr:not(.groupHeader),
    > tbody {
      border: 1px solid $border-light;
    }
  }

  &.key-value-table td:first-child {
    width: 50%;
    padding-left: 1rem;
    line-height: 24px;
  }

  .material-icons {
    transform: translateY(2px);
  }

  &.table-striped.white tbody tr:nth-of-type(odd) {
    background-color: white;
  }

  &.table-striped tbody {
    tr:nth-of-type(odd) {
      background-color: white;
    }
    tr:nth-of-type(even) {
      background-color: $atlas-background;
    }
  }

  td.valign-top {
    vertical-align: top;
  }
}

/* Placeholder */
.placeholder {
  background: #eee;
  text-align: center;
  height: 150px;
  width: 100%;
}
.placeholder h2 {
  line-height: 140px;
  margin: 0;
}

/*
 * Buttons
 */
.btn {
  white-space: nowrap;
}

.btn-secondary {
  @include button-variant(#fff, #d3d8de);
  background: white;
}

.input-group-text .icon {
  font-size: 18px;
}
.input-group-btn .btn {
  padding: 6px 15px;
  font-size: 14px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-group {
  .dropdown-toggle {
    display: flex;

    &:after {
      font-family: 'Material Icons';
      border: 0;
      @include material_icon('more_horiz');
    }
  }
}

.material-icons {
  font-size: 20px;
}

.dropdown-toggle-split {
  margin-left: 2px !important;
}

/* Dropdowns */
.dropdown-menu {
  a {
    cursor: pointer;
  }

  .action-item {
    display: block;
    padding: 0.5rem 1.25rem;
    clear: both;
    font-weight: 400;
    line-height: 1.5;
    color: #333 !important;
    white-space: nowrap;

    &--danger {
      color: #dc3545 !important;
    }

    &:focus,
    &:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
}

/*
 * Page Headers
 */
.page-header {
  font-size: 1rem;

  .page-title h1 {
    margin: 0;
    line-height: 1.8rem;
  }
}

/*
 * Buttons
 */
.btn-lg {
  font-size: 0.875rem;
}

/*
 * Forms
 */
.form-header {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  border-bottom: #ccc 1px solid;
}

input.number-input-amount {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

input.number-input-percent {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

input {
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: 300;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 300;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: 300;
  }
}

label {
  margin-bottom: 0.3rem;
}

.form-text {
  font-weight: 300;
}

.form-check-input {
  margin-top: 0.2rem;
}

// Validation fix for invalid fields within input-group
.input-group.is-invalid {
  ~ .invalid-feedback {
    display: block;
  }
}

/*
 * Mod
 */
.mod {
  position: relative;
}
.mod-heading-underlined .mod-heading {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.mod-background .mod-heading,
.mod-background .mod-body {
  padding-left: 10px;
  padding-right: 10px;
}

/* Modals */
.modal {
  padding-left: 1rem;
  padding-right: 1rem;

  &--narrow {
    width: 380px;
  }
}

.modal-content {
  overflow: hidden;
}

.modal-header {
  padding: 0.75rem 1rem;
  background: #f1f1f1;

  .address {
    margin: 0;
    color: #9f9f9f;
  }
}

.modal-sub-header {
  margin: 0.75rem 1rem;
  padding: 0;
  background: #ffffff;
  border-bottom: solid 1px #cecece;
}

.modal-footer .btn {
  padding: 0.4rem 1rem;

  &.btn-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.modal-footer {
  padding: 0.75rem 1rem;
}

.modal-dialog.modal-page {
  height: 100%;
  width: 100%;
  margin: 0;
}
.modal-dialog.modal-page .modal-content {
  height: 100%;
  overflow-y: hidden;
}
.modal-dialog.modal-page .modal-body {
  height: calc(100% - 57px);
  overflow-y: auto;
}
.modal-dialog.modal-page .modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.modal-dialog.modal-page .modal-body {
  overflow-y: auto;
}

.modal-dialog.modal-sm {
  width: 420px;
  max-width: 100%;
}
.modal-dialog.modal-xl {
  max-width: 95vw;
}

/* Form Loading State */
.modal-loading-state {
  padding: 50px 20px;
  text-align: center;
}

/* Zero States */
.zero-state-inline {
  font-style: italic;
}

/* Error States */
.error-state {
  padding: 20px;
}
.error-state .icon {
  font-size: 40px;
}
.error-state h1 {
  margin-bottom: 0;
  margin-top: 15px;
}
.error-state p:last-child {
  margin-bottom: 0;
}

.error-state-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* State structure */
.v-state {
  padding: 20px;
}
.v-state .icon {
  font-size: 40px;
}
.v-state h1 {
  margin-bottom: 0;
  margin-top: 15px;
}
.v-state p:last-child {
  margin-bottom: 0;
}

/* Carousel */
.carousel-images {
  .carousel.slide {
    height: 100%;
  }
  .carousel-inner {
    height: 100%;
  }
  .carousel-item {
    height: 100%;

    .item {
      height: 100%;
      background: #000;
    }
    .carousel-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }

  .carousel-indicators {
    width: 80%;
    left: 10%;
    margin: 0;
    bottom: 12px;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 1px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #000\9;
      border-radius: 10px;

      &.active {
        width: 12px;
        height: 12px;
        margin: 0;
        background-color: #fff;
      }
    }
  }
}

.carousel-control {
  opacity: 1;
  transition: all 0.02s linear;
}
.carousel-control.left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.35) 90%
  );
}
.carousel-control.right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.35) 90%
  );
}
.carousel-control:hover {
  opacity: 1;
}
.carousel-control .icon-next,
.carousel-control .icon-prev {
  height: 46px;
  width: 46px;
  line-height: 39px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #303030;
  background-size: 30%;
}
.carousel-control:active .icon-next,
.carousel-control:active .icon-prev {
  transform: translateY(2px);
  background-color: rgba(255, 255, 255, 1);
}
.carousel-control:hover .icon-next,
.carousel-control:hover .icon-prev {
  background-color: rgba(255, 255, 255, 1);
}

/* Stats */
.stat-wrap {
  margin-bottom: 3px;
  overflow: hidden;
  line-height: 22px;
}
.stat-wrap .stat-label {
  color: #777;
  font-size: 14px;
  margin-right: 5px;
  line-height: 25px;
}
.stat-wrap .stat {
  font-size: 18px;
  font-weight: 500;
  float: right;
}

/* Stats - Primary */
.stat-wrap.primary .stat-label {
  line-height: 29px;
}
.stat-wrap.primary .stat {
  font-size: 24px;
}

.list-label {
  font-size: 13px;
  color: #999999;
}
.list-value {
}
.list-detail {
  font-size: 11px;
  color: #777777;
}

/* Breadcrumbs */
.breadcrumb-wrap .breadcrumb {
  display: inline-flex;
  border: 0;
  font-size: 12px;
  text-transform: none;
  background: 0;
  padding-left: 0;
}

.breadcrumb {
  @include design-system.text-body-small();

  a {
    @include design-system.text-body-small($bold: true);
  }

  .breadcrumb-item:not(.active) a {
    color: design-system.color('interaction-dark');
  }
}

/* Property Details Page - Carousel */
.property-details-image .carousel-bg-images .carousel.slide {
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Section */
.section-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}
.well pre {
  background-color: #e2e2e2;
  padding: 8px 12px;
}

.section-label {
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Seller Intake Form Details Module */
.form-details-section {
  margin-bottom: 30px;
}
.form-details-section dt {
  font-weight: normal;
  color: #8e8d8d;
  clear: left;
}
.form-details-section dt,
.form-details-section dd {
  line-height: 1.25;
}
.form-details-section dd {
  margin-bottom: 8px;
}
.form-details-section .section-label {
  margin-bottom: 15px;
  color: #e83024;
  font-size: 14px;
}

@media (min-width: 768px) {
  .form-details-section .section-label {
    text-align: center;
  }
  .form-details-section dt {
    text-align: right;
  }
  .form-details-section dt,
  .form-details-section dd {
    margin-bottom: 5px;
  }
}

/* Tiles */
.tile {
  background-color: #fff;
  padding: 10px;
  position: relative;
  height: 80px;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.tile:not(:last-child):after {
  content: '';
  right: -10px;
  position: absolute;
  top: 50%;
  height: 100%;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 16px solid #e3e3e3;
  transform: translateY(-50%);
  z-index: 1;
}
.tile:not(:last-child):before {
  content: '';
  right: -9px;
  position: absolute;
  top: 50%;
  height: 100%;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 16px solid #fff;
  transform: translateY(-50%);
  z-index: 2;
}
.tile:first-child {
  border-left: 1px solid #e3e3e3;
}
.tile:last-child {
  border-right: 1px solid #e3e3e3;
}

.tile-value {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.8px;
}

/* Definition Lists - Block Data */
.def-block {
  margin-bottom: 0.75rem;

  dt {
    text-transform: uppercase;
    color: #aaa;
    margin-bottom: 2px;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 1px;

    a {
      text-transform: capitalize;
    }
  }

  dd {
    font-size: 13px;
    margin-bottom: 0.1rem;
  }

  .resource-link {
    .material-icons {
      font-size: 14px;
      transform: translateY(2px);
    }
  }
}

/* Badges */
.badge {
  letter-spacing: 0.025rem;
  text-transform: uppercase;

  &.sm {
    font-size: 0.625rem;
    padding: 0.3em 0.8em;
  }
}

.badge-secondary {
  background-color: #e2e2e2;
  color: #545454;
}

/* Popovers */
.popover {
  width: 250px;
  color: #222;

  .table tbody td {
    padding-right: 40px;
  }
}
.popover .popover-title {
  background: #f8f8f8;
  color: #000;
  border: 0;
}
.popover .popover-content {
  font-size: 13px;
}
.popover .popover-footer {
  border-top: 1px solid #eee;
  margin-top: 12px;
  padding-top: 10px;
  text-align: right;
}
.popover .popover-footer .cancel-link {
  margin-right: 6px;
}
.popover--stretch {
  width: auto;
  max-width: none;
}

/* Map Markers */
.marker-red {
  background: red;
}

/* Google Maps Autocomplete */
.pac-container {
  z-index: 10000 !important;
} /* This is needed to show the Google Autocomplete dropdown. */

.fadeIn {
  animation: 0.2s fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-success {
  background-color: #62c462 !important;
  color: #fff !important;
}

.form-nested {
  padding-left: 15px;
  border-left: 5px solid #ececec;
  padding-bottom: 8px;

  .form-group:last-child {
    margin-bottom: 0;
  }

  .form-row > .form-group {
    margin-bottom: 0;
  }
}

.nav {
  &.nav-tabs {
    .nav-item {
      .nav-link {
        display: block;
        padding: 0.5rem 1rem;
      }
    }
  }
}

tabset {
  .nav-pills {
    margin-bottom: 0.5rem;

    .nav-item + .nav-item {
      border-left: 1px #ddd solid;
      padding: 0;
    }

    .nav-item:first-child .nav-link {
      padding-left: 0;
    }

    .nav-link {
      padding: 0.125rem 0.5rem;
      color: #337ab7;

      &,
      &.active {
        text-decoration: none;
        background-color: transparent;
      }

      &.active {
        color: #111;
        cursor: default;
      }
    }
  }
}

// ngx typeahead
typeahead-container.dropdown-menu {
  // 1 more than .modal, so typeaheads within modals aren't hidden
  // !important fixes issue introduced in ngx-bootstrap 3.3.0 that applies
  // z-index: 1000 to the dropdown.
  // see https://github.com/valor-software/ngx-bootstrap/issues/5144
  z-index: 1051 !important;
}

/**
 * TOOLTIP STYLES
 */

%atlas-tooltip {
  &.tooltip.show {
    opacity: 1;
  }

  &.info-tooltip {
    .tooltip-inner {
      font-family: 'Roboto';
      font-size: 12px;
      line-height: 16px;
      max-width: 264px;
      padding: 16px;
      text-align: left;
    }
  }
}

.atlas-ngx-tooltip {
  &--white {
    @extend %atlas-tooltip;

    // Useful if you want to clear out all default styling
    // and embed your own layout or component in the tooltip.
    // The selectors below override ngx-bootstrap defaults.

    .tooltip-inner {
      color: #000;
      background-color: #fff;
      box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
      border-radius: 0;
    }

    .tooltip-arrow.arrow::before {
      border-top-color: #fff;
    }
  }
}

.closing-dashboard-tooltip {
  @extend %atlas-tooltip;
  .tooltip-inner {
    background-color: #505167;
  }
  .tooltip-arrow::before {
    border-top-color: #505167;
  }
}

.module {
  display: block;
  padding: 10px 20px;
  background: #e0e4e4;
}

.current-column .economics-subheader {
  padding-right: 1.5rem;
}

.summary-row {
  margin: 0;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;

  &__label {
    display: flex;
    align-items: center;
    width: 33%;
    color: #929292;
  }

  &__value {
    display: flex;
    align-items: center;
    width: 67%;
    display: flex;
  }

  & + .summary-row {
    border-top: 1px #ddd solid;
    padding-top: 0.5rem;
  }
}

.deal-search-autocomplete.mat-autocomplete-visible {
  margin-top: 12px;
  max-height: 90vh;

  @media (max-width: 767px) {
    position: fixed;
    max-height: 85vh;
    left: 0;
    top: 41px;
  }
}

.cdk-overlay-container {
  z-index: 10000;
}

@import 'design-system-overrides';
