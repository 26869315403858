@use '@angular/material' as mat;
@use '../../core/' as *;

@mixin atlas-datepicker {
  .op-datepicker-focus-override {
    .mat-mdc-text-field-wrapper {
      border-color: color(interaction) !important;
    }
  }

  mat-datepicker-content {
    @include border($color: color(interaction), $radius: md);
    @include mat.elevation(0);
    @include margin-vertical(2);

    .mat-calendar {
      height: auto; // don't reserve space for a 6th row, but expand the box instead
    }

    .mat-calendar-table-header-divider {
      display: none; // remove the line under the days of the week
    }

    .mat-calendar-controls {
      @include margin(0);
    }

    .mat-calendar-period-button {
      @include padding(1);
      .mdc-button__label {
        color: color(text-secondary);
      }
    }

    .mat-calendar-header {
      @include padding(4);
      @include padding-bottom(0);
    }

    .mat-calendar-content {
      @include padding(4);
    }

    // Remove the month name in the empty space of the first week.
    .mat-calendar-body-label {
      visibility: hidden;

      &[colspan='7'] {
        display: none;
      }
    }
  }
}
