@use '../core' as *;

@mixin op-overlay($bg: color('white'), $fg: color(text-primary)) {
  @include border();
  @include radius(sm);

  background-color: $bg;
  color: $fg;
}

@mixin op-overlay-dark($bg: color(text-primary), $fg: color('white')) {
  @include op-overlay($bg, $fg);
}
