$op-icon-size-sm: 12px;
$op-icon-size: 16px;
$op-icon-size-lg: 20px;

$op-icon-upsizing: 2px;

@mixin op-icon($size) {
  width: $size;
  height: $size;

  font-size: $size;
}

$op-inline-icon-offset: $op-icon-size + spacing(1);

@mixin offset-for-icon() {
  margin-left: $op-inline-icon-offset;
}
